import React from 'react'
import './totalpurchase.scss'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Header from '../landing/Header/Header';
import { Link, useHistory } from 'react-router-dom';
import { API_URL } from '../../utils/ApiUrl';
import axios from 'axios';
import Loader from '../../hooks/loader';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import useAuth from '../../hooks/useAuth';
import ReCAPTCHA from "react-google-recaptcha";

const Totalpurchase = () => {
    let firstOption =localStorage.getItem('firstOption')
    const [capchaa, setCapcha] = useState(null)
   let reee=  localStorage.getItem('recapchaa')
    let locaWallet=localStorage.getItem('connectorId')
    const { login, logout } = useAuth();
    const { account } = useWeb3React()
    const history = useHistory()
    const [loader, setLoader]=useState(false)
    var token = localStorage.getItem("accessToken");
    var retoken = localStorage.getItem("refreshToken");    
    var signn = localStorage.getItem("sign");
    const[totalVotes,setTotalVotes]=useState(0)

    const [votee, setVotee] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [timeshow, setTimeshow] = useState(false);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const time = 1711125600000;
            const diff = time - now;
            if (diff <= 0) {
                clearInterval(interval);
                setTimeshow(true);
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);
            setDay(days);
            setHour(hours);
            setMin(mins);
            setSec(secs);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);
    const [isChecked5, setIsChecked5] = useState(false);

 
    const handleChange1 = () => {
        setIsChecked1(!isChecked1);
        setIsChecked2(false);
        setIsChecked3(false);
        setIsChecked4(false);
        setIsChecked5(false);
        setVotee(`0.1`)
    };

    const handleChange2 = () => {
        setIsChecked2(!isChecked2);
        setVotee(`0.2`)
        setIsChecked1(false);
        setIsChecked3(false);
        setIsChecked4(false);
        setIsChecked5(false);
    };

    const handleChange3 = () => {
        setIsChecked3(!isChecked3);
        setVotee(`0.3`)
        setIsChecked1(false);
        setIsChecked2(false);
        setIsChecked4(false);
        setIsChecked5(false);
    };

    const handleChange4 = () => {
        setIsChecked4(!isChecked4);
        setVotee(`0.4`)
        setIsChecked1(false);
        setIsChecked3(false);
        setIsChecked2(false);
        setIsChecked5(false);
    };

    const handleChange5 = () => {
        setIsChecked5(!isChecked5);
        setVotee(`0.5`)
        setIsChecked1(false);
        setIsChecked3(false);
        setIsChecked4(false);
        setIsChecked2(false);
    };

    useEffect(()=>{
        getProfile()
    },[account])
    const getProfile = async () => {
          setLoader(true);
    
          var config = {
            method: "get",
            url: `${API_URL}users/profile`,
    
            headers: {
              Authorization: "Bearer " + token,
            },
            data: {},
          };
    
          await axios(config)
            .then(function (res) {
              const resData = res?.data?.data;
              setTotalVotes(resData?.totalVotes)
            //   setStatusNextScreen(resData?.isPrivateSaleBuyer)
              setLoader(false);
            //   toast.success(resData?.message);
            //   setLoader(false);
            //   history.push("/login");
            })
            .catch(function (err) {
              if (err?.response?.data?.statusCode === 401) {
                // Handle blocked user
                refreshTokeen()
              } else {
                setLoader(false);
                // toast.error(err?.response?.data?.message, {
                //   position: "top-right",
                //   autoClose: 2000,
                // });
              }
              console.log("err response: ", err?.response);
            //   setLoader(false);
              throw err;
            });
        
      };
      const refreshTokeen =async()=>{
        setLoader(true);
    
        var config = {
          method: "get",
          url: `${API_URL}/users/profile`,

          data: {
            
                "refreshToken": retoken
            
          },
        };
  
        await axios(config)
          .then(function (res) {
            const resData = res?.data?.data;
        localStorage?.setItem("accessToken", resData?.accessToken);
          localStorage?.setItem("refreshToken", resData?.refreshToken);
          setLoader(false)
          getProfile()
       
          })
          .catch(function (err) {
            diconnect()
            localStorage.clear();
           history.push('/')
            setLoader(false);
            throw err;
          });
     
      }

      const submit = async () => {
        // if(statusNextScreen){
        setLoader(true);
        var config = {
          method: "post",
          url: `${API_URL}votes-v2`,
  
          headers: {
            Authorization: "Bearer " + token,
          },
          data: {
            "vote": firstOption,
            "sign":signn,
            "recaptcha":reee,
            "option":parseFloat(votee),
          },
        };
  
        await axios(config)
          .then(function (res) {
            const resData = res?.data?.data;
            // setTotalVotes(resData.totalVotes)
            handleShow()
            setLoader(false);
          //   toast.success(resData?.message);
          //   setLoader(false);
          //   history.push("/login");
          })
          .catch(function (err) {
            // if (err?.response?.data?.statusCode === 401) {
              // Handle blocked user
            //   refreshTokeen()
            // } else {
            //   setLoader(false);
              toast.error(err?.response?.data?.message, {
                position: "top-right",
                autoClose: 2000,
              });
            // }
            console.log("err response: ", err?.response);
            setLoader(false);
          //   setLoader(false);
            throw err;
          });
        // }else{
        //     history.push('/totalpurchase')
        // }
      
    };

    const diconnect =async()=>{
        await logout(locaWallet);
      }

  

  return (
    <>
    <Header />
    {loader&&
      <Loader/>}
        <section className="main-purchase">
            <div className="innersubmit">
                <div className="votingtimer">
                    <h6 className="votingtimerhead">Poll #2 voting ends in:</h6>
                    {timeshow ? (
                        <p className="endpara">Timer has ended!</p>
                    ) : (
                        <div className="timerspans">
                            <div className="maintime">
                                <div className="innertimer">
                                    <span className="innertimertext">{hour ? hour : 0}</span>
                                </div>
                                <p className="timertext">HOURS</p>
                            </div>
                            <div className="maintime">
                                <div className="innertimer">
                                    <span className="innertimertext">{min ? min : 0}</span>
                                </div>
                                <p className="timertext">MINUTES</p>
                            </div>
                            <div className="maintime">
                                <div className="innertimer">
                                    <span className="innertimertext">{sec ? sec : 0}</span>
                                </div>
                                <p className="timertext">SECONDS</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="myvotesmain">
                        <div className="myvotesleft">
                            <p className="myvotesleftpara">Your votes</p>
                            <span className="questionmain">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62566 11.0696C7.38726 11.0696 7.19446 10.8768 7.19446 10.6384C7.19446 10.0744 7.27446 9.5872 7.43366 9.1776C7.55046 8.8688 7.74006 8.5576 8.00086 8.2432C8.19286 8.0144 8.53686 7.68 9.03446 7.2416C9.53206 6.8024 9.85526 6.4528 10.0049 6.192C10.1545 5.9312 10.2281 5.6464 10.2281 5.3376C10.2281 4.7784 10.0097 4.288 9.57366 3.8648C9.13766 3.4416 8.60246 3.2304 7.96886 3.2304C7.35686 3.2304 6.84566 3.4224 6.43606 3.8056C6.13686 4.0856 5.91206 4.4808 5.76326 4.9912C5.66086 5.3416 5.31926 5.5656 4.95686 5.5224C4.49766 5.468 4.19526 5.0136 4.32646 4.5696C4.52806 3.8872 4.86566 3.3376 5.33846 2.92C5.99526 2.3384 6.86406 2.048 7.94486 2.048C9.08886 2.048 10.0017 2.3592 10.6833 2.9824C11.3649 3.6056 11.7049 4.3584 11.7049 5.2416C11.7049 5.7528 11.5849 6.224 11.3457 6.6544C11.1065 7.0856 10.6377 7.6096 9.94086 8.2272C9.47286 8.6424 9.16646 8.9488 9.02246 9.1456C8.87846 9.3424 8.77206 9.5688 8.70326 9.824C8.65206 10.0136 8.61686 10.292 8.59766 10.6576C8.58566 10.888 8.39446 11.0688 8.16406 11.0688H7.62566V11.0696ZM7.11446 13.1336C7.11446 12.6816 7.48086 12.3152 7.93286 12.3152C8.38486 12.3152 8.75126 12.6816 8.75126 13.1336C8.75126 13.5856 8.38486 13.952 7.93286 13.952C7.48086 13.952 7.11446 13.5856 7.11446 13.1336Z" fill="white" />
                                </svg>
                                <div className="hoverdiv">
                                <h6 className="hoverhead">Voting weight</h6>
                                <p className="hoverpara">All testnet participants have 1 vote. Private sale buyers have 1 vote for every 7000 tokens they purchased.</p>
                                </div>
                            </span>
                        
                        </div>
                        <p className="myvotesnumber">
                      {totalVotes}
                        </p>
                    </div>
                <div className="opininionmain">
                    <h6 className="opinionhead">How much would you like to purchase?*</h6>
                    <div className="votemain">
                        <div className="checkboxmain">
                            <div class="content">
                                <label className={`checkBox ${isChecked1 ? 'checked' : ''}`}>
                                    <input checked={isChecked1} type="checkbox"  id="ch1" onChange={handleChange1} />
                                    <div class="transition"></div>
                                </label>
                            </div>
                            <p className="checkboxpara">0.1 ETH</p>
                        </div>
                        <div className="checkboxmain">
                            <div class="content">
                                <label className={`checkBox ${isChecked2 ? 'checked' : ''}`}>
                                    <input checked={isChecked2} type="checkbox" id="ch1" onChange={handleChange2} />
                                    <div class="transition"></div>
                                </label>
                            </div>
                            <p className="checkboxpara">0.2 ETH</p>
                        </div>
                        <div className="checkboxmain">
                            <div class="content">
                                <label className={`checkBox ${isChecked3 ? 'checked' : ''}`}>
                                    <input checked={isChecked3} type="checkbox" id="ch1" onChange={handleChange3} />
                                    <div class="transition"></div>
                                </label>
                            </div>
                            <p className="checkboxpara">0.3 ETH</p>
                        </div>
                        <div className="checkboxmain">
                            <div class="content">
                                <label className={`checkBox ${isChecked4 ? 'checked' : ''}`}>
                                    <input checked={isChecked4} type="checkbox" id="ch1" onChange={handleChange4} />
                                    <div class="transition"></div>
                                </label>
                            </div>
                            <p className="checkboxpara">0.4 ETH</p>
                        </div>
                        <div className="checkboxmain">
                            <div class="content">
                                <label className={`checkBox ${isChecked5 ? 'checked' : ''}`}>
                                    <input checked={isChecked5} type="checkbox" id="ch1" onChange={handleChange5} />
                                    <div class="transition"></div>
                                </label>
                            </div>
                            <p className="checkboxpara">0.5 ETH</p>
                        </div>
                    </div>
                    <button disabled={votee?false:true} onClick={submit}  className="submitbtn">Submit</button>
                </div>
            </div >
            <p className="lastpara">
            *The amount chosen is not necessarily the amount you will be able to purchase with.
            </p>
        </section >

        <Modal
            className="connectwallet-modal buy-modal"
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Vote Submitted</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="buy-modal">
                    <img src="\assets\happy.svg" alt="img" className="img-fluid" />
                    <p>
                    Vote Successfully Submitted
                    </p>
                    <Link to="/votingresult" className="btn-common" onClick={handleClose}>
                    See voting results
                    </Link>
                </div>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default Totalpurchase