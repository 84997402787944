
import React, { useState } from "react";
import "./banner.scss";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'
import useAuth from '../../../hooks/useAuth'
import { useHistory } from "react-router-dom";
import useSignature from '../../../hooks/userSign';
import { API_URL } from "../../../utils/ApiUrl";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../hooks/loader";
const Banner = () => {
  var signn = localStorage.getItem("sign");
  const [loader ,setLoader]=useState(false)
  const {userSign}=useSignature()
  const history = useHistory()
  const { account } = useWeb3React()
  const { login, logout } = useAuth();
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)
    // localStorage.removeItem('connectorId')
    // localStorage.removeItem('flag');
    // window.location.reload()
  };
  const handleShow = () => setShow(true);

  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const time = 1711125600000;
      const diff = time - now;
      if (diff <= 0) {
        clearInterval(interval);
        setTimeshow(true);
        return;
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((diff % (1000 * 60)) / 1000);
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{
    // trustWallet()
    if(account&&!signn){
     userSignatur()
    }
    else if(account&&signn){
      signUp(signn)
    }
  },[account])
  const userSignatur=async()=>{
  try{
    let v =await userSign()
    if (v){
      localStorage?.setItem("sign",v);
      console.log(v,'sign');
      signUp(v)
    }

  }
  catch(err){
console.log(err,'err noor sign');
localStorage.removeItem('connectorId')
localStorage.removeItem('flag');
window.location.reload()
  }
  }
const signUp=async(v)=>{

    // const lowerCaseEmail = email.toLowerCase();
    // const isValid = ValidateForm();
    // console.log("form validation: ", isValid);
    // if (isValid) {
      setLoader(true);

      var config = {
        method: "post",
        url: `${API_URL}auth/signup`,
        data: {
          "walletAddress": account.toLowerCase(),
          "sign": v,
        },
      };

      await axios(config)
        .then(function (res) {
          const resData = res?.data?.data;
          console.log(resData,'resData sigup');
          localStorage?.setItem("accessToken", resData?.accessToken);
          localStorage?.setItem("refreshToken", resData?.refreshToken);
          if(parseInt(resData?.user?.pendingWhitelistVotes)===0)
        {
          history.push("/votingresult")
          setLoader(false);
        }else{
          history.push("/submitvote")
          setLoader(false);
        }
    
        })
        .catch(function (err) {
          if (err?.response?.data?.statusCode === 409) {
            // Handle blocked user
            signIn(v)
          } else {
            handleShow()
            setLoader(false);
          }
          console.log("err api signup ", err?.response,err?.response?.data?.statusCode);
          // setLoader(false);
          throw err;
        });
    // }

}
const signIn=async(v)=>{

  // const lowerCaseEmail = email.toLowerCase();
  // const isValid = ValidateForm();
  // console.log("form validation: ", isValid);
  // if (isValid) {
    setLoader(true);

    var config = {
      method: "post",
      url: `${API_URL}auth/signin`,
      data: {
        "walletAddress": account.toLowerCase(),
        "sign": v,
      },
    };

    await axios(config)
      .then(function (res) {
        const resData = res?.data?.data;
        console.log(resData,'resData sigin');
        localStorage?.setItem("accessToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        if(parseInt(resData?.user?.pendingWhitelistVotes)===0)
        {
          history.push("/votingresult")
          setLoader(false);
        }else{
          history.push("/submitvote")
          setLoader(false);
        }
     
  
      })
      .catch(function (err) {
        // if (err?.response?.data?.statusCode === 409) {
          // Handle blocked user
        // } else {
        //   toast.error(err?.response?.data?.message, {
        //     position: "top-right",
        //     autoClose: 2000,
        //   });
        // }
        handleShow()
        console.log("err api signup ", err?.response);
        setLoader(false);
        throw err;
      });
  // }

}
  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      try {
         login("walletconnect"); 
        // handleClose();
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };


  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
    //   handleClose();


    }
  };
  return (
    <>
    {loader&&
      <Loader/>}
      <section className="main-banner">
        <div className="innerbanner">
          <h4 className="bannerhead">Poll #2 - Cast Your Vote</h4>
          <p className="bannerpara">Following the close results of the first poll, with the bigger debate being whether at all there should be a Testnet-exclusive sale round, we’ve decided to launch a second 24-hour survey to ensure we truly capture the community's will.
          <br/>
          <br/>
          All testnet participants and private sale contributors can vote in this poll.
          </p>
          <div className="votingtimer">
            <h6 className="votingtimerhead">Poll #2 voting ends in:</h6>
            {timeshow ? (
                  <p className="endpara">Timer has ended!</p>
                ) : (
                  <div className="timerspans">
                    <div className="maintime">
                      <div className="innertimer">
                        <span className="innertimertext">{hour ? hour : 0}</span>
                      </div>
                      <p className="timertext">HOURS</p>
                    </div>
                    <div className="maintime">
                      <div className="innertimer">
                        <span className="innertimertext">{min ? min : 0}</span>
                      </div>
                      <p className="timertext">MINUTES</p>
                    </div>
                    <div className="maintime">
                      <div className="innertimer">
                        <span className="innertimertext">{sec ? sec : 0}</span>
                      </div>
                      <p className="timertext">SECONDS</p>
                    </div>
                  </div>
                )}
          </div>
          <p className="connectwalletpara">
          Connect the wallet you used for the Testnet or Private Sale.
          </p>   
          <div className="walletmain">
            <div className="walletinner metamaskHide" onClick={connectMetaMask1}>
              <p className="walletupperpara">
              Desktop 
              </p>
              <img src="\assets\metamask.svg" alt="walletimg" className="walletimg" />
              <h6 className="walletname">MetaMask</h6>
            </div>
            <div className="walletinner" onClick={trustWallet}>
              <p className="walletupperpara">
              Mobile  
              </p>
              <img src="\assets\walletconnect.svg" alt="walletimg" className="walletimg" />
              <h6 className="walletname">WalletConnect</h6>
            </div>
          </div>
        </div>
        <div className="disclaimermain">
          <p className="disclaimerpara">
          Important: This poll is about the token allocation for the special DOP sale round for testnet users.
          </p>
          <h6 className="disclaimerhead">
          It has no effect on the rewards for users who have completed the Testnet.
          </h6>
        </div> 
      </section>


      <Modal className="cannotmodal" show={show} onHide={handleClose} centered>
        <Modal.Body>
        <h6 className="redhead">Sorry! You cannot Cast a Vote.</h6>
        <p className="dullpara">Looks like we couldn't find your wallet address in our data base.</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;