

import { useCallback, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { getLibraryForSign } from "../utils/web3React";
// import useLibrary from "./useLibrary";
import { ethers } from "ethers";
import useWeb3 from "../hooks/useWeb3";
import { connectorsByName } from "../utils/web3React";


const useSignature = (wab) => {
  const web3 = useWeb3()
  const { account } = useWeb3React();
  // const { library1, connectorId } = useLibrary();
  let library = null
  // if (web3 && account) {
    const connectorId = typeof window !== 'undefined' ? window.localStorage.getItem("connectorId") : null;
    if (connectorId === 'injected' && account) {
      library = getLibraryForSign(web3?.givenProvider);
    } else {
      if (connectorsByName.walletconnect.provider) {
        library = getLibraryForSign(connectorsByName.walletconnect.provider);
      }
    }



  const sign = useCallback(
    async () => {
      console.log("hereere", account, web3)
      const connectorId = window.localStorage.getItem("connectorId")
      // console.log("Ssoos", web3)
      if (connectorId === 'injected' && account) {
        library = getLibraryForSign(web3?.givenProvider);
      } else {
        // return console.log("connectorsByName", connectorsByName['walletconnect'])
        if (connectorsByName?.walletconnect?.provider) {
          library = getLibraryForSign(connectorsByName?.walletconnect?.provider);
        }
      }
      // console.log("oooow", library)
      if (library && account) {
        console.log("web3========================", web3, account, library)
        try {
            let tring= "weareDOPdev"
            let tring1= "weareDOPdev"
          let signature = await library.send(
            'personal_sign',
            [`${tring}${account.toLowerCase()}${tring}`, account.toLowerCase()]
          );
          // console.log("here2222",signature)
          return signature;
        } catch (error) {
          console.log("!!!!!!!!xx", error)
          throw error;
        }
      }
    },
    [account, web3,connectorId]
  );

  return { userSign: sign };
};

export default useSignature;