import React from 'react'
import './header.scss'
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from '@web3-react/core';

const Header = () => {
  // console.log(account,'accountaccountaccount');
  const { account } = useWeb3React()
  let locaWallet=localStorage.getItem('connectorId')
  const { login, logout } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const disconnect=async()=>{
    await logout(locaWallet);
    localStorage.clear();
    history.push('/')
window.location.reload()
    // }, 3000);

  }

  const isHomePage = location.pathname === '/';
  return (
    <section className="mainheader">
      <div className="custom-container">
        <div className="innerheader">
          <a>
            <img src="\logo.svg" alt="logoimg" className="logoimg" />
          </a>
          <div className="twice-btn">
            <a target='_blank' href="https://docs.google.com/document/d/1lCL1s3m3RCHloPlxKbSu5xL1QabPuCrqctRIaPAHKW4/edit?usp=sharing" className='faq-btn'>FAQ</a>
            {account && (
              <a onClick={disconnect} className="disconnectbtn">
                Disconnect
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header