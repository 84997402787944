import React from 'react'
import "./votingresult.scss"
import Header from '../landing/Header/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { API_URL } from '../../utils/ApiUrl';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loader from '../../hooks/loader';
import { useWeb3React } from '@web3-react/core';
import useAuth from '../../hooks/useAuth';

const Votingresult = () => {
    let locaWallet=localStorage.getItem('connectorId')
    
    const { login, logout } = useAuth();
    const { account } = useWeb3React()
    const history = useHistory()
    const [loader, setLoader]=useState(false)
    var token = localStorage.getItem("accessToken");
    var retoken = localStorage.getItem("refreshToken");
    const [timeshow, setTimeshow] = useState(false);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [tolaVotes,setTotalVotes]=useState(null)
    const [votes1,setVotes1]=useState(null)
    const [votes2,setVotes2]=useState(null)
    const [votes3,setVotes3]=useState(null)
    const [votes4,setVotes4]=useState(null)
    const [vote5,setVotes5]=useState(null)
    const [votes1per,setVotes1per]=useState(null)
    const [votes2per,setVotes2per]=useState(null)
    const [votes3per,setVotes3per]=useState(null)
    const [votes4per,setVotes4per]=useState(null)
    const [vote5per,setVotes5per]=useState(null)
    const [voteStatus,setVotesStatus]=useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
          const now = new Date().getTime();
          const time = 1711125600000;
          const diff = time - now;
            if (diff <= 0) {
                clearInterval(interval);
                setTimeshow(true);
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);
            setDay(days);
            setHour(hours);
            setMin(mins);
            setSec(secs);
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    const getProfile = async () => {
        setLoader(true);
  
        var config = {
          method: "get",
          url: `${API_URL}votes-v2-count`,
  
          headers: {
            Authorization: "Bearer " + token,
          },
          data: {},
        };
  
        await axios(config)
          .then(function (res) {
            const resData = res?.data?.data;
            console.log(resData,'sdfsdf');
            setLoader(false);
            console.log(resData);
            setVotes1(resData?.noAllocation)
            setVotes2(resData?.uptoOnePointFiveBillion)
            // setVotes3(resData?.twoPointFiveBillion)
            // setVotes4(resData?.threePointFiveBillion)
            // setVotes5(resData?.fourPointFiveBillion)
            // setVotes1per((resData?.noAllocation/resData?.totalVotes)*100)
            // setVotes2per((resData?.onePointFiveBillion/resData?.totalVotes)*100)
            // setVotes3per((resData?.twoPointFiveBillion/resData?.totalVotes)*100)
            // setVotes4per((resData?.threePointFiveBillion/resData?.totalVotes)*100)
            // setVotes5per((resData?.fourPointFiveBillion/resData?.totalVotes)*100)
          })
          .catch(function (err) {
            if (err?.response?.data?.statusCode === 401) {
              // Handle blocked user
              refreshTokeen()
            } else {
                diconnect()
                history.push('/')
                setLoader(false);
              // toast.error(err?.response?.data?.message, {
              //   position: "top-right",
              //   autoClose: 2000,
              // });
            }
            console.log("err response: ", err?.response);
            
            throw err;
          });
      
    };
    const refreshTokeen =async()=>{
      setLoader(true);
  
      var config = {
        method: "get",
        url: `${API_URL}/users/profile`,

        data: {
          
              "refreshToken": retoken
          
        },
      };

      await axios(config)
        .then(function (res) {
          const resData = res?.data?.data;


          setLoader(true);
                    localStorage?.setItem("accessToken", resData?.accessToken);
        localStorage?.setItem("refreshToken", resData?.refreshToken);
        getProfile()
        getProfile1()
     
        })
        .catch(function (err) {
         history.push('/')
         diconnect()
         localStorage.clear();
          setLoader(false);
          throw err;
        });
   
    }
    const getProfile1 = async () => {
        setLoader(true);
  
        var config = {
          method: "get",
          url: `${API_URL}users/profile`,
  
          headers: {
            Authorization: "Bearer " + token,
          },
          data: {},
        };
  
        await axios(config)
          .then(function (res) {
            const resData = res?.data?.data;
            setTotalVotes(resData?.totalVotes)
            setVotesStatus(resData?.vote)
            setLoader(false);
          //   toast.success(resData?.message);
          //   setLoader(false);
          //   history.push("/login");
          })
          .catch(function (err) {
            if (err?.response?.data?.statusCode === 401) {
              // Handle blocked user
              refreshTokeen()
            } else {
              setLoader(false);
              // toast.error(err?.response?.data?.message, {
              //   position: "top-right",
              //   autoClose: 2000,
              // });
            }
            console.log("err response: ", err?.response);
          //   setLoader(false);
            throw err;
          });
      
    };

    useEffect(()=>{
        getProfile()
        getProfile1()
    },[account])
    const diconnect =async()=>{
        await logout(locaWallet);
      }
    return (
        <>
            <Header />
            {loader&&
      <Loader/>}
            <section className="main-result">
                <div className="innersubmit">
                    <div className="votingtimer">
                        <h6 className="votingtimerhead">Poll #2 voting ends in:</h6>
                        {timeshow ? (
                            <p className="endpara">Timer has ended!</p>
                        ) : (
                            <div className="timerspans">
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{hour ? hour : 0}</span>
                                    </div>
                                    <p className="timertext">HOURS</p>
                                </div>
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{min ? min : 0}</span>
                                    </div>
                                    <p className="timertext">MINUTES</p>
                                </div>
                                <div className="maintime">
                                    <div className="innertimer">
                                        <span className="innertimertext">{sec ? sec : 0}</span>
                                    </div>
                                    <p className="timertext">SECONDS</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="myvotesmain">
                        <div className="myvotesleft">
                            <p className="myvotesleftpara">Your votes</p>
                            <span className="questionmain">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M7.62566 11.0696C7.38726 11.0696 7.19446 10.8768 7.19446 10.6384C7.19446 10.0744 7.27446 9.5872 7.43366 9.1776C7.55046 8.8688 7.74006 8.5576 8.00086 8.2432C8.19286 8.0144 8.53686 7.68 9.03446 7.2416C9.53206 6.8024 9.85526 6.4528 10.0049 6.192C10.1545 5.9312 10.2281 5.6464 10.2281 5.3376C10.2281 4.7784 10.0097 4.288 9.57366 3.8648C9.13766 3.4416 8.60246 3.2304 7.96886 3.2304C7.35686 3.2304 6.84566 3.4224 6.43606 3.8056C6.13686 4.0856 5.91206 4.4808 5.76326 4.9912C5.66086 5.3416 5.31926 5.5656 4.95686 5.5224C4.49766 5.468 4.19526 5.0136 4.32646 4.5696C4.52806 3.8872 4.86566 3.3376 5.33846 2.92C5.99526 2.3384 6.86406 2.048 7.94486 2.048C9.08886 2.048 10.0017 2.3592 10.6833 2.9824C11.3649 3.6056 11.7049 4.3584 11.7049 5.2416C11.7049 5.7528 11.5849 6.224 11.3457 6.6544C11.1065 7.0856 10.6377 7.6096 9.94086 8.2272C9.47286 8.6424 9.16646 8.9488 9.02246 9.1456C8.87846 9.3424 8.77206 9.5688 8.70326 9.824C8.65206 10.0136 8.61686 10.292 8.59766 10.6576C8.58566 10.888 8.39446 11.0688 8.16406 11.0688H7.62566V11.0696ZM7.11446 13.1336C7.11446 12.6816 7.48086 12.3152 7.93286 12.3152C8.38486 12.3152 8.75126 12.6816 8.75126 13.1336C8.75126 13.5856 8.38486 13.952 7.93286 13.952C7.48086 13.952 7.11446 13.5856 7.11446 13.1336Z" fill="white" />
                                </svg>
                                <div className="hoverdiv">
                                <h6 className="hoverhead">Voting weight</h6>
                                <p className="hoverpara">All testnet participants have 1 vote. Private sale buyers have 1 vote for every 7000 tokens they purchased.</p>
                                </div>
                            </span>
                        
                        </div>
                        <p className="myvotesnumber">
                        {tolaVotes}
                        </p>
                    </div>
                    <div className="opininionmain">
                        <h6 className="opinionhead">In your opinion, what should be the token allocation for the testnet-exclusive sale round?</h6>
                        <p className="votebreakpara">Vote Breakdown</p>
                        <div className="mainprogress">
                            <div className="innerprogress">
                                <p className="progresspara">Zero Allocation
                            {voteStatus==='no allocation'&&
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM11.768 15.64L16.768 9.64L15.232 8.36L10.932 13.519L8.707 11.293L7.293 12.707L10.293 15.707L11.067 16.481L11.768 15.64Z" fill="white"/>
                            </svg>
                            }

                                </p>

                                <p className="progresspara formblnone"> {votes1}%</p>
                            </div>
                            <ProgressBar now={votes1} />
                            <p className="progressparambl d-none"> {votes1}%</p>
                        </div>
                        <div className="mainprogress">
                            <div className="innerprogress">
                                <p className="progresspara">1.5B DOP Tokens
                                {voteStatus==='upto 1.5B'&&
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C13.1819 21 14.3522 20.7672 15.4442 20.3149C16.5361 19.8626 17.5282 19.1997 18.364 18.364C19.1997 17.5282 19.8626 16.5361 20.3149 15.4442C20.7672 14.3522 21 13.1819 21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21ZM11.768 15.64L16.768 9.64L15.232 8.36L10.932 13.519L8.707 11.293L7.293 12.707L10.293 15.707L11.067 16.481L11.768 15.64Z" fill="white"/>
                            </svg>
                            }
                                </p>
                                <p className="progresspara formblnone">{votes2}%</p>
                            </div>
                            <ProgressBar now={votes2} />
                            <p className="progressparambl d-none"> {votes2}%</p>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default Votingresult