import React from 'react';
import { ToastContainer } from 'react-bootstrap';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Submitvote from './components/Submitvote/Submitvote';
import Totalpurchase from './components/Totalpurchase/Totalpurchase';
import Votingresult from './components/Votingresult/Votingresult';
import useEagerConnect from "./hooks/useEagerConnect";

function App() {
  useEagerConnect();
  return (
    <>
            <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
      <Router>
        <Route
          exact
          path='/'
          render={() => (<Landing />)}
        />
        <Route
          exact
          path='/submitvote'
          render={() => (<Submitvote />)}
        />
               <Route
          exact
          path='/votingresult'
          render={() => (<Votingresult />)}
        />
                       <Route
          exact
          path='/totalpurchase'
          render={() => (<Totalpurchase />)}
        />
      </Router>
    </>
  );
}

export default App;
